import * as React from "react"
import { useFlexSearch } from "react-use-flexsearch"


function SearchDocsResults ({ searchQuery, docsIndexStore }) {

    const docsResult = useFlexSearch(
        searchQuery,
        JSON.stringify(docsIndexStore.index),
        docsIndexStore.store,
    ) 

    if (docsResult.length === 0) {
        return (
            <h4>No results</h4>
        )
    } else {
        return (
            <>
            {docsResult.map((result) => (
                result.slug
              ))}
            </>
        )
    }


}

export default SearchDocsResults;